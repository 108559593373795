export const promoCategoriesMap = {
  Bitcoin: "bootstrap",
  "Cash Back": "cashRefund", // "cash-stack",
  Christmas: "asterisk",
  Deposit: "gift",
  Easter: "egg",
  Exclusive: "sun",
  "Free spins": "trophy",
  Halloween: "coneStriped", // "cone-striped", // ?
  "High Roller": "personCheck", // "person-check",
  "No deposit": "xOctagon", // "x-octagon",
  Other: "plug", // ?
  Reload: "reload", // "arrow-clockwise",
  "St. Patrick's Day": "suitClub", // "suit-club",
  Thanksgiving: "bookmark",
  "Valentine's day": "bookmarkHeart", // "bookmark-heart",
  Weekly: "calendarRange", // "calendar-week",
  Welcome: "percent",
};
