<template>
  <div v-if="categories.length > 0 && disptype === 'block'" class="grid-view mt-2">
    <v-sheet v-for="category in categories" :key="category.name" class="text-center promoCategories" border>
      <SmartLink :to="category?.uri || ''">
        <v-icon
          v-if="promoCategoriesMap"
          :icon="`$${promoCategoriesMap[category.name]}`"
          class="d-block ma-auto mb-4"
          size="x-large"
          color="iconColor"
        />
        <span class="text-capitalize"> {{ category.cnt }} {{ getLabel(category) }} Bonuses </span>
      </SmartLink>
    </v-sheet>
  </div>
  <v-list v-else-if="categories.length > 0">
    <v-list-item v-for="category in categories" :key="category.name">
      <SmartLink :to="category?.uri || ''"> {{ category.cnt }} {{ getLabel(category) }} Bonuses </SmartLink>
    </v-list-item>
  </v-list>
</template>
<script setup>
import { promoCategoriesMap } from "~/lib/promoCategoriesMap";
const props = defineProps({
  categories: {
    type: Array,
    required: true,
  },
  disptype: {
    type: String,
    required: false,
    default: "block",
  },
  anypage: { type: Object, required: false, default: () => {} },
});

const getLabel = category => category.label || category.name;

const addSlash = uri =>
  uri && typeof uri === "string" ? (uri.startsWith("https://") || uri.startsWith("/") ? uri : "/" + uri) : "/";
</script>
